body{
  font-size: 1rem !important;
}
span.btn{
    cursor: pointer;
}

table thead th.empty, table .thead-light th.empty{
    border: 0 none;
    background-color: unset;
}

.table tr.thead-light {
    color: #5c6873;
    background-color: #e4e7ea;
    border-color: #c8ced3;
}

.nav-tabs-custom {background-color: #f0f3f5;}

.nav-tabs-custom-vertical .nav-item .nav-link.active{background-color: #f0f3f5;}

.cmb-1 {
  margin-bottom: 0.125rem !important;
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed{
  border: 0;
  box-shadow: 0 2px 2px 0 rgb(60 75 100 / 14%), 0 3px 1px -2px rgb(60 75 100 / 12%), 0 1px 5px 0 rgb(60 75 100 / 20%);
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn-group-vertical>.btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]),.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn-group>.btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]){
  box-shadow:none;border:0
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn-group-vertical>.btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]):before,.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn-group>.btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]):before{
  position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;content:"";border-radius:inherit;border:0;box-shadow:0 1px 1px 0 rgba(60,75,100,.14),0 2px 1px -1px rgba(60,75,100,.12),0 1px 3px 0 rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]){
  border:0;box-shadow:0 1px 1px 0 rgba(60,75,100,.14),0 2px 1px -1px rgba(60,75,100,.12),0 1px 3px 0 rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn.focus:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]),.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn:focus:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]){
  border:0;
  box-shadow:0 4px 5px 0 rgba(60,75,100,.14),0 1px 10px 0 rgba(60,75,100,.12),0 2px 4px -1px rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .card{
  border:0;box-shadow:0 1px 1px 0 rgba(60,75,100,.14),0 2px 1px -1px rgba(60,75,100,.12),0 1px 3px 0 rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .dropdown-menu{
  border:0;box-shadow:0 4px 5px 0 rgba(60,75,100,.14),0 1px 10px 0 rgba(60,75,100,.12),0 2px 4px -1px rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-footer.c-footer-fixed{
  border:0;box-shadow:0 2px 2px 0 rgba(60,75,100,.14),0 3px 1px -2px rgba(60,75,100,.12),0 1px 5px 0 rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed{
  border:0;box-shadow:0 2px 2px 0 rgba(60,75,100,.14),0 3px 1px -2px rgba(60,75,100,.12),0 1px 5px 0 rgba(60,75,100,.2)
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .modal-content{
  border:0;box-shadow:0 24px 38px 3px rgba(60,75,100,.14),0 9px 46px 8px rgba(60,75,100,.12),0 11px 15px -7px rgba(60,75,100,.2)
}
  
  @media print {
    body {
      padding: 1rem;
    }
    table {
      width: 100%;
    }
  }
  
.table td{
  padding: 0.30rem !important;
}